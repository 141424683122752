.contact-form-container {
  .contact-form-header {
    text-align: center;
    font-size: 24px;
    padding-top: 32px;
    font-weight: 500;

    .logo {
      width: 200px;
      z-index: 2;
      transform: translateX(-50%);
      color: darkblue;
    }
  }

  .contact-form {
    text-align: center;
    padding: 32px 64px 32px 64px;

    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px 24px;
    align-items: center;
    text-align: start;
    max-width: 724px;
    margin-inline: auto;

    form {
      display: contents;
    }

    .text-area {
      grid-column: 1 / 3;
    }
  }

  .successfully-submited-text {
    text-align: center;
    font-weight: 700;
    margin-top: 32px;
  }
}

@media screen and (max-width: 600px) {
  .contact-form-container {
    .contact-form {
      grid-template-columns: 1fr;
      padding: 56px;

      .text-area {
        grid-column: 1;
      }

      .submit-button-container {
        grid-column: 1;
      }
    }
  }
}

.admin-modal-container {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.4);

  .modal-container {
    border-radius: 8px;
    padding: 2rem;
    background-color: white;
    width: 25em;

    .form-group {
      display: flex;
      flex-direction: column;
      margin-bottom: 1rem;

      input,
      textarea {
        border: 1px solid black;
        border-radius: 8px;
        padding: 0.3rem;
        font-size: 1rem;
      }

      textarea {
        height: 90px;
        resize: none;
        padding-block: 12px;
      }

      label {
        margin-bottom: 0.2rem;
      }
    }
    .submit-btn {
      height: 40px;
      border-radius: 8px;
      outline: none;
      background-color: #2998ff;
      border: 0;
      color: white;
      font-size: 15px;
      margin-top: 8px;
      margin-inline: auto;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        background-color: #1b73c6;
      }

      &:active {
        background-color: rgb(55, 145, 229);
      }
    }
  }
}

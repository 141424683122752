body {
  direction: rtl;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

div#NagishLiTrigger {
  filter: hue-rotate(-33deg);

  .nagishli-submenu-state-icon,
  .nagishli-option-icon,
  .nagishli-trigger-icon {
    width: 70%;
    height: 80%;
    display: flex;
    justify-content: center;
    padding: 6px;

    img {
      width: 30px;
    }
  }
}

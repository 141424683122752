.text-input,
.text-area {
  input,
  textarea {
    height: 36px;
    border: 1px solid #dcdcdc;
    border-radius: 8px;
    padding-inline: 8px;
    font-size: 14px;
    transition: border-color 0.1s ease;
    resize: none;
    width: 100%;
    box-sizing: border-box;

    &:hover:not(:focus) {
      border-color: rgb(161, 161, 161);
    }

    &:focus {
      border-color: #2998ff;
      outline: none;
    }
  }

  textarea {
    height: 90px;
    padding-block: 12px;
  }

  input[type="date"] {
    background: white;
    width: 100%;
    box-sizing: border-box;
  }

  label {
    display: block;
    margin-bottom: 4px;
  }
}

.submit-button-container {
  grid-column: 1 / 3;
  display: flex;
  align-items: center;
  justify-content: center;

  .submit-button {
    padding-inline: 32px;
  }
}

.submit-button {
  height: 40px;
  border-radius: 8px;
  padding-inline: 12px;
  outline: none;
  background-color: #2998ff;
  border: 0;
  color: white;
  font-size: 15px;
  cursor: pointer;
  transition: 0.1s ease background-color;

  &:not(.on-video):hover {
    background-color: #1b73c6;
  }

  &:not(.on-video):active {
    background-color: rgb(55, 145, 229);
  }
}

.on-video {
  background: rgb(197 140 54 / 92%);
  width: 140px;
  z-index: 2;
  color: #fff;
}

.on-video:hover {
  background-color: rgb(144 102 38 / 92%);
}

.main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .main-navbar {
    background: rgb(252, 239, 238);
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1), 0 6px 12px rgba(0, 0, 0, 0.04);
    width: 100%;
    position: fixed;
    top: 0;
    padding-block: 6px;
    overflow: hidden;
    z-index: 999;

    .navbar-items-container {
      width: 100%;
      display: flex inline;
      padding-inline: 24px;
      align-items: center;
      justify-content: space-between;

      .social-icons-container {
        display: flex;
        gap: 16px;
      }
      @keyframes pulse {
        0% {
          transform: scale(1);
        }
        50% {
          transform: scale(1.07);
        }
        100% {
          transform: scale(1);
        }
      }
      .navbar-item {
        cursor: pointer;

        &.center-bold {
          padding-inline-end: 48px;
          font-weight: 600;
        }

        &.contact-me {
          transition: transform 0.15s;
        }

        &.animate {
          animation: pulse 0.3s ease-in-out; // Adjust the duration and easing as needed
        }

        .whatsapp-icon {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          width: 28px;
          height: 28px;
          background-color: #25d366;
          border-radius: 20%;
          color: white;
          text-decoration: none;
          transition: transform 0.2s;
        }

        .whatsapp-icon:hover {
          transform: scale(1.1);
        }
        .instagram-icon {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          width: 28px;
          height: 28px;
          text-decoration: none;
          transition: transform 0.2s;
        }

        .instagram-icon:hover {
          transform: scale(1.1);
        }
      }
    }
  }

  .parallax-image {
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .main-video-container {
    width: 100%;
    max-height: 80vh;
    overflow: hidden;
  }

  .main-container-below-video {
    width: 100%;
    background-color: white;

    .contact-me-on-main-page {
      display: flex;
      justify-content: center;
      margin-top: 16px;
    }

    .information-container {
      display: flex;
      gap: 64px;
      justify-content: space-around;
      padding: 8px 64px 64px 64px;

      .about-us-container {
        .about-us-content {
          max-width: 600px;
          line-height: 1.4;
        }
      }
    }

    .players-cards-container-image {
      background: rgb(252, 239, 238);
      padding: var(--padding, 64px);
      padding-top: 48px;

      .players-header {
        text-align: center;
        font-size: 24px;
        padding-bottom: 16px;
        font-weight: 500;
      }

      .players-cards-container {
        display: flex;
        flex-basis: 50%;
        flex-wrap: wrap;
        gap: 48px;
        justify-content: center;
      }

      .players-row-container {
        display: flex;
        justify-content: space-between;

        .avira-image {
          max-width: 450px;
          max-height: 300px;
          border-radius: 10px;
        }
      }
    }
  }
}

h1 {
  margin-bottom: 12px;
}

@media screen and (max-width: 978px) {
  .main-container .main-container-below-video .information-container {
    flex-wrap: wrap;
    gap: 24px;
  }

  .main-container .main-container-below-video .players-cards-container-image {
    padding-inline: 40px;
  }

  .main-container
    .main-container-below-video
    .players-cards-container-image
    .players-cards-container {
    gap: 0;
  }
}

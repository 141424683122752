.table-wrapper {
  width: 100%;

  .table {
    display: block;
    overflow: hidden;
    table-layout: auto;
    border-collapse: collapse;
    box-shadow: 0px 10px 10px #ccc;
    border-radius: 10px;
    white-space: nowrap;
    width: 100em;
    max-width: 80%;
    margin: auto;
    overflow-x: auto;
  }

  thead {
    background-color: #ccc;
    color: #222;
  }

  th,
  td {
    padding: 12px;
  }

  td {
    border-top: 0.5px solid #ddd;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  tbody tr:hover {
    background-color: #eee;
  }

  .expand {
    width: 100%;
  }
  .text-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 0; /* Initial setting to help with the flexbox */
  }

  .actions {
    display: flex;
    justify-content: space-around;

    svg {
      cursor: pointer;
    }

    .delete-btn {
      color: #e77b78;
    }
  }
}

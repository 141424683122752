body.modal-open {
  overflow: hidden;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(3, 17, 40, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: default;
  z-index: 2;
  padding-top: 25px;
}

.modal-content {
  background-color: #fff;
  border-radius: 8px;
  position: relative;
  width: 90%;
  height: 88%;
  max-width: 1100px;
  max-height: 880px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  cursor: default;
}

.close-button {
  position: absolute;
  top: 2px;
  right: 2px;
  background: none;
  border: none;
  font-size: 28px;
  cursor: pointer;
  color: black;
  z-index: 1;
  padding-inline: 0;
  width: 36px;
}

.modal-body {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.player-img-container {
  position: relative;
}

.player-img {
  height: 100%;
  object-fit: cover;
}

.player-img-back {
  display: none;
}

.modal-text {
  flex: 1;
  padding: 40px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  text-align: right;
  align-self: center;

  & > h2 {
    grid-row: 1;
    grid-column: 1 / 3;
  }

  & > p {
    grid-row: 2;
    grid-column: 1 / 3;
  }

  .contact-me-in-player-modal {
    grid-row: 3;
    font-weight: 600;
    cursor: default;

    .contact-me-text {
      cursor: pointer;
    }
  }
}

h2 {
  margin-block: 0;
  font-size: 32px;
  font-weight: bold;
}

p {
  margin-top: 10px;
  font-size: 16px;
}

@media (min-width: 769px) and (max-width: 1024px) {
  .player-img {
    max-width: 50%;
  }
}

@media (max-width: 768px) {
  .modal-content {
    overflow-y: auto;
    height: auto;
    max-height: 82%;
    width: min-content;
  }

  .modal-body {
    flex-direction: column;
    height: auto;
    width: min-content;
    min-width: 280px;
  }

  .player-img {
    width: auto;
    height: 65vh;
    object-fit: contain;
  }

  .player-img-back {
    display: block;
  }

  .modal-text {
    width: 85%;
    padding: 10px 20px 20px 20px;
  }

  h2 {
    font-size: 28px;
  }

  .modal-text .contact-me-in-player-modal2 {
    grid-row: 1;
    grid-column: 2;
  }
}

.player-card-container {
  cursor: pointer;
  max-width: calc(100% / var(--players-per-row, 4) - 50px);
  flex-basis: calc(100% / var(--players-per-row, 1));

  &.modal-close {
    transition: transform 0.25s ease-out;

    &:hover {
      transform: scale(1.05);
    }
  }

  .player-image {
    width: 100%;
  }

  .player-info-container {
    padding: 8px 16px 16px;
  }

  .player-name {
    font-size: 28px;
    text-align: center;
    font-weight: 600;
  }

  .player-description {
    margin-top: 4px;
  }
}

@media screen and (max-width: 470px) {
  .player-card-container {
    --width: 450px;
    --players-per-row: 1.61;
    --flex-basis: unset;

    .player-name {
      font-size: 24px;
    }

    .player-description {
      font-size: 15px;
    }
  }

  .players-cards-container-image {
    --padding: 80px;
  }
}

@media screen and (min-width: 470px) and (max-width: 720px) {
  .player-card-container {
    --players-per-row: 1.75;
    --flex-basis: unset;
  }
}

@media screen and (min-width: 721px) and (max-width: 990px) {
  .player-card-container {
    .player-name {
      font-size: 24px;
    }
  }
}

@media screen and (min-width: 721px) and (max-width: 10240px) {
  .player-card-container {
    --width: 250px;
    --players-per-row: 4;
    --flex-basis: 25%;
  }
}

.admin-information-container {
  width: 100%;

  .admin-information-fields-container {
    display: flex;
    flex-direction: column;
    padding-inline: 130px;

    .info-field-item {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 24px;
    }

    .edit-field-btn {
      height: 40px;
      border-radius: 8px;
      outline: none;
      background-color: #2998ff;
      border: 0;
      color: white;
      font-size: 15px;
      margin-top: 8px;
      margin-inline: auto;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        background-color: #1b73c6;
      }

      &:active {
        background-color: rgb(55, 145, 229);
      }
    }
  }
}

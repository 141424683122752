.parallax-video-container {
  height: 80vh;
  width: 100%;
  overflow: hidden;
  position: relative;
  background-color: rgb(252, 239, 238);

  video {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    object-fit: contain; /* Ensure the entire video is shown */
    transform: translate(-50%, -50%);
    z-index: 1;
    image-rendering: crisp-edges;
    image-rendering: -webkit-optimize-contrast; /* For WebKit browsers */
  }

  .logo-and-contact-container {
    position: absolute;
    width: 200px;
    top: 48%;
    left: 50%;
    z-index: 2;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;

    .logo {
      width: 200px;
    }
  }

  @media (max-width: 768px) {
    height: 55vh;

    .logo {
      width: 200px;
    }
  }

  @media (max-width: 480px) {
    height: 55vh;

    .logo {
      width: 150px;
    }
  }
  @media (max-width: 380px) {
    height: 55vh;

    .logo {
      width: 150px;
    }
  }
}
